import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	h1 {
		font-size: 48px;
		font-weight: 600;
		line-height: 1.5;
		margin: 140px 0 140px 0;

		.name {
			font-size: 72px;
			color: #CEBCA6;
		}
	}

	h2 {
		font-size: 30px;
		font-weight: 600;
	}

	p.description {
		font-weight: 400;
		font-size: 18px;
		letter-spacing: 0.4px;
		line-height: 28px;
		max-width: 600px;
		padding-right: 20px;
		text-align: left;
	}

	@media (max-width: 576px) {
		h1 {
			font-size: 32px;
			margin: 70px 0 70px 0;

			.name {
				font-size: 32px;
			}
		}

		h2 {
			font-size: 26px;
		}

		p.description {
			font-size: 16px;
		}
	}
`;

export default function Home() {
	return (
		<Container>
			<h1 className="">
				Hi 👋, my name is <br />
				<span className="name">Kamil Borowski</span> <br />
				and I write code.
			</h1>
			<h2>About</h2>
			<p className="description">
				I'm a Software Engineer with over 8 years of professional experience spanning over front-end, back-end and cloud development. These days I work with Go, C#, AWS and React. Currently based in Copenhagen, Denmark.
			</p>
		</Container>
	);
};