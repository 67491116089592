import React from 'react';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import Layout from './components/layout/layout';
import Home from './pages/home/home';

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [{
			path: "",
			element: <Home />
		}]
	},
]);

export default function App() {
	return (
		<RouterProvider router={router} />
	);
}