import React from 'react';
import styled from 'styled-components';
import Lines from './lines';
import Navigation from './navigation';
import { Outlet } from "react-router-dom";

const LayoutContainer = styled.main`
    display: flex;
	position: relative;
	flex-direction: column;
    width: 100%;
	max-width: 1024px;
	margin-left: auto;
    margin-right: auto;
	padding: 0 40px 0 40px;

    @media (max-width: 576px) {
        flex-direction: column;
	}
`;

export default function Layout({ children }) {
	return (
		<LayoutContainer>
			<Lines amount={2}/>
			<Navigation />
			<Outlet />
		</LayoutContainer>
	);
};