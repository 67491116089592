import React from 'react';
import styled from 'styled-components';

const LinesWrapper = styled.div`
	position: absolute;
	margin-left: 40px;
	top: 0;
	right: 0;
	z-index: -1;

	.lines-container {
		display: flex;
		position: relative;
		min-height: 100vh;
		height: 100%;
	}

	& div.line {
		width: 2px;
		background-color: #F3F2F2;
		min-height: 100vh;
		height: 100%;
		display: inline-block;
		position: relative;

		// animation-name: linesGrowth;
		// animation-duration: 3s;	
	}

	& div.line:first-child .gold-element {
		height: 150px;
		position: absolute;
    	top: calc(90% - 150px);
	}

	& div.gold-element {
		display: inline-block;
		width: 4px;
		background-color: #CEBCA6;
		margin-left: -1px;
	}

	& div.line:not(:first-child) {
		margin-left: 251px;
	}

	& div.line:not(:first-child) .gold-element {
		top: 210px;
		position: absolute;
		height: 90px;
	}

	@media (max-width: 768px) and (min-width: 576px) {
		& div.line:not(:first-child).gold-element {
			margin-top: 420px;
			height: 90px;
		}
	}

	@media (max-width: 576px) {
		display: none;
	}

	@keyframes linesGrowth {
		from {
			height: 0vh;
		}
		to {
			height: 100vh;
		}
	}	
`;

export default function Lines(props) {
	return (
		<LinesWrapper className="s-medium s-large">
			<div className="lines-container">
				{Array.from({ length: props.amount }, (v, k) =>
					<div className="line" key={k}>
						<div className="gold-element"></div>
					</div>
				)}
			</div>
		</LinesWrapper>
	);
}