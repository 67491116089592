import React from 'react';
import styled from 'styled-components';

const StyledMainNavigation = styled.ul`
	position: relative;
	display: flex;
	margin: 70px 0 0 auto;
	list-style: none;
	padding: 0;		
	
	li {
		font-weight: 500;
		color: #6D6D6D;
		font-size: 14px;
		margin: 0 8px;
		text-transform: uppercase;
		height: 21px;
		letter-spacing: 1px;
		display: inline-block;
		box-sizing: border-box;

		&:first-of-type {
			margin-left: 0px;
		}
	}

	& li a {
		color: inherit;
	}
	
	& li:hover {
		border-bottom: 2px solid #6D6D6D;
		cursor: pointer;
	}
	
	& li.highlight {
		color: #C92E2E;
	}
	
	& li.highlight:hover {
		border-color:#C92E2E;
	}

	@media (max-width: 576px) {
		margin: 70px 0 0 0;
	}

	@media (max-width: 768px) and (min-width: 576px) {
	}

	@media (max-width: 768px) {
	}
`;
export default function Navigation() {

	const links = [
		{
			text: "Github",
			link: "https://github.com/BorowskiKamil"
		},
		{
			text: "LinkedIn",
			link: "https://www.linkedin.com/in/kamil-borowski/",
		},
		{
			text: "Resume",
			link: "https://kborowski.me/resume.pdf",
			highlight: true
		},
		{
			text: "Contact",
			link: "mailto:kb.kamil.borowski@gmail.com"
		}
	];

	const children = links.map((v, k) => (
		<li key={k} className={v.highlight && "highlight"}>
			<a target="_blank" rel="noreferrer" href={v.link}>{v.text}</a>
		</li>
	));

	return (
		<StyledMainNavigation>
			{children}
		</StyledMainNavigation>
	);
}